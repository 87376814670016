import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import * as _ from 'lodash';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/styles/withStyles';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Autocomplete from 'UI/autocomplete';
import LabelInput from 'UI/labelInput';
import Header from 'UI/header';
import Button from 'UI/button';
import arrowBack from 'assets/svg/arrow_left.svg';
import Footer from 'UI/footer';
import DragnDrop from 'UI/DragnDrop';
import DocumentCards from 'UI/documentCards';
import DotLine from 'UI/dotLine';
import RadioOptions from 'UI/radioOptions';
import TravellerSelection from 'UI/travellerSelection';
import { ROLES, SUPPLIER_TYPES, TIMEOUT } from 'utils/consts';
import { getButtonText, shouldShowDiffToggle } from 'utils/common';
import FlightDiff from 'components/itineraryMaker/flightDiff';
import axios from 'axios';
import { baseURL } from 'utils/apiEndpoints';

class CreateFlight extends Component {
  constructor(props) {
    super(props);
    const { flight, itineraryActionType, originalFlight } = this.props;
    let prevState = {};
    this.originalFlight = {};
    if (flight) {
      const processedData = this.processFlightData(flight);
      prevState = {
        ...processedData,
        valid: true,
        edit: true,
      };
      this.showDiffToggle = shouldShowDiffToggle(itineraryActionType, originalFlight);
      if (this.showDiffToggle) {
        this.originalFlight = this.processFlightData(originalFlight);
      }
    }
    let source = 'FLYNOTE';
    if (flight?.source === 'CUSTOMER' || (itineraryActionType === 'generate_voucher' && !flight)) {
      source = 'CUSTOMER';
    }
    this.state = {
      flightType: 'One way',
      departureFlightData: { ...this.getFlightDetails() },
      returnFlightData: { ...this.getFlightDetails() },
      travellers: {
        adults: 0,
        children: 0,
        infants: 0,
      },
      costPrice: 0,
      sellingPrice: 0,
      baggage: {
        value: '',
        valid: false,
      },
      supplier: {
        value: '',
        valid: false,
        item: {},
      },
      documents: [],
      refundable: 'Non-refundable',
      cancellationAmount: 0,
      notes: '',
      valid: false,
      edit: false,
      showDiff: false,
      source,
      ...prevState,
    };
    this.flightTypes = ['One way', 'Round trip'];
    this.cabins = ['Economy', 'First Class', 'Business', 'Premium Economy']
      .map((c) => ({ name: c }));
    this.stops = ['Direct flight', '+ 1 stop', 'Multi stop'];
    this.baggages = ['0 kgs', '15 kgs', '20 kgs', '25 kgs', '30 kgs', '35 kgs', '40 kgs', '45 kgs', 'On request']
      .map((b) => ({ name: b }));
    this.dropInput = React.createRef();
  }

  getFlightDetails = () => {
    const date = new Date();
    return {
      from: {
        value: '',
        valid: false,
      },
      to: {
        value: '',
        valid: false,
      },
      flightDate: date,
      dateVerified: false,
      invoiceId: '',
      flightNumber: '',
      terminal: '',
      cabinClass: {
        value: '',
        valid: false,
      },
      pnr: '',
      noOfStops: '',
    };
  };

  getConvertedFlightData = (data) => {
    const {
      from = {}, to = {}, flightDate, flightNumber,
      terminal, noOfStops, cabinClass, pnr = '', invoiceId = '',
    } = data;
    return {
      from: {
        value: from?.name,
        valid: true,
        item: from,
      },
      to: {
        value: to?.name,
        valid: true,
        item: to,
      },
      invoiceId,
      flightDate: moment(flightDate),
      dateVerified: true,
      flightNumber: flightNumber || '',
      terminal: terminal || '',
      noOfStops,
      pnr,
      cabinClass: {
        value: cabinClass || '-',
        valid: true,
      },
    };
  };

  processFlightData = (flight) => {
    const {
      flightType = 'One way', departureFlightData, returnFlightData, documents = [], isRefundable,
      cancellationAmount = 0, notes, travellers, costPrice, sellPrice, supplier, baggage, source = 'FLYNOTE',
    } = flight;
    return {
      flightType,
      departureFlightData: this.getConvertedFlightData(departureFlightData),
      returnFlightData: flightType === 'One way'
        ? { ...this.getFlightDetails() } : this.getConvertedFlightData(returnFlightData),
      travellers,
      costPrice: costPrice / 100,
      sellingPrice: sellPrice / 100,
      baggage: {
        value: baggage,
        valid: true,
      },
      refundable: isRefundable ? 'Refundable' : 'Non refundable',
      cancellationAmount,
      supplier,
      notes,
      documents,
      source,
    };
  };

  handleChange = (field, value) => {
    const { getSuggestions } = this.props;
    const { [field]: itm } = this.state;
    if (itm.constructor.name === 'Object' && field !== 'travellers') {
      this.setState({
        [field]: {
          value,
          valid: false,
        },
      });
      getSuggestions('cities', value);
    } else {
      this.setState({ [field]: value }, this.validityChecker);
    }
  };

  toggleDiff = () => {
    this.setState((prevState) => ({ showDiff: !prevState.showDiff }));
  };

  handleSelection = (field, item, accessor) => {
    this.setState({
      [field]: {
        value: item[accessor],
        valid: true,
      },
    }, this.validityChecker);
  };

  handleSupplierChange = (value) => {
    const { getSuggestions } = this.props;
    getSuggestions('supplier', value, {
      supportedServices: [SUPPLIER_TYPES.AIR_TRANSFER],
    });
    this.setState({
      supplier: {
        value,
        valid: false,
        item: {},
      },
    });
  };

  handleSupplierSelection = (item) => {
    this.setState({
      supplier: {
        value: item.name,
        valid: true,
        item,
      },
    }, this.validityChecker);
  };

  handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!_.isEmpty(e.dataTransfer.files)) {
      const { documents } = this.state;
      const modDocuments = [...documents];
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        const { type } = file;
        if (['application/pdf', 'image/png'].includes(type)) {
          modDocuments.push(file);
        }
      }
      await this.setState({ documents: modDocuments }, this.validityChecker);
      e.dataTransfer.clearData();
    }
  };

  handleUpload = async (e) => {
    console.log('handleUpload');
    const files = e.target.files;
    if (!_.isEmpty(files)) {
      const { documents } = this.state;
      const modDocuments = [...documents];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const { type } = file;
        if (['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'].includes(type)) {
          modDocuments.push(file);
        }
      }
      await this.setState({ documents: modDocuments }, this.validityChecker);
      console.log('handleUpload2');
      this.getFlightData();
    }
    this.dropInput.current.value = '';
  };

  // handleUpload = (e) => {
  //   console.log('handleUpload');
  //   const params = {
  //     preventDefault: () => {
  //     },
  //     stopPropagation: () => {
  //     },
  //     dataTransfer: {
  //       files: e.target.files,
  //       clearData: () => {
  //       },
  //     },
  //   };
  //   this.handleDrop(params);
  //   this.dropInput.current.value = '';
  // };

  getFlightData = async () => {
    const { showSnackbar, headers } = this.props;
    const { documents } = this.state;

    console.log('hit 1');
    const data = {
      images: await Promise.all(documents.map(async (document) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          // When the file is read as base64, this event will trigger
          reader.onloadend = () => {
            if (reader.result) {
              resolve({
                base64Data: reader.result.toString(),
                imageType: document.type,
              });
            } else {
              reject();
            }
          };

          // Read the file as base64 (Data URL)
          reader.readAsDataURL(document);
        });
      })),
    };
    try {
      await this.setState({
        autoFillInProcess: true,
      });
      const response = await axios({
        method: 'post',
        url: `${baseURL}itinerary/getFlightData`,
        data,
        headers,
        timeout: TIMEOUT,
      });
      const { flightData } = response.data;

      const { departureFlightData, returnFlightData, baggage } = flightData;
      this.setState({
        autoFillInProcess: false,
        flightType: _.isEmpty(returnFlightData) ? 'One way' : 'Round trip',
        baggage: {
          value: baggage,
          valid: true,
        },
        departureFlightData: {
          from: {
            value: departureFlightData.from?.name,
            valid: true,
            item: departureFlightData.from,
          },
          to: {
            value: departureFlightData.to?.name,
            valid: true,
            item: departureFlightData.to,
          },
          flightDate: departureFlightData.flightDate,
          dateVerified: true,
          flightNumber: departureFlightData.flightNumber || '',
          terminal: departureFlightData.terminal || '',
          noOfStops: departureFlightData.noOfStops,
          cabinClass: {
            value: departureFlightData.cabinClass || '-',
            valid: true,
          },
        },
        returnFlightData: _.isEmpty(returnFlightData) ? {} : {
          from: {
            value: returnFlightData.from?.name,
            valid: true,
            item: returnFlightData.from,
          },
          to: {
            value: returnFlightData.to?.name,
            valid: true,
            item: returnFlightData.to,
          },
          flightDate: returnFlightData.flightDate,
          dateVerified: true,
          flightNumber: returnFlightData.flightNumber || '',
          terminal: returnFlightData.terminal || '',
          noOfStops: returnFlightData.noOfStops,
          cabinClass: {
            value: returnFlightData.cabinClass || '-',
            valid: true,
          },
        },
      });
    } catch (error) {
      console.log('Error is ', error);
      showSnackbar('Something went wrong, please try again!', 'error');
    }
  }

  handleRemove = (index) => {
    const { documents } = this.state;
    const modDocuments = [...documents];
    modDocuments.splice(index, 1);
    this.setState({ documents: modDocuments }, this.validityChecker);
  };

  handleFlightDataChange = (flightType, field, value) => {
    const { getSuggestions } = this.props;
    const { [flightType]: data } = this.state;
    const modData = { ...data };
    if (field === 'from' || field === 'to') {
      getSuggestions('airports', value, { includeAddressTypes: ['airport'] });
    }
    if (modData[field].constructor.name === 'Object' && field !== 'flightDate') {
      modData[field] = {
        value,
        valid: false,
      };
    } else {
      modData[field] = value;
    }
    if (field === 'flightDate') {
      modData.dateVerified = true;
    }
    this.setState({ [flightType]: modData }, this.validityChecker);
  };

  handleFlightDataSelection = (flightType, field, item, accessor) => {
    const { [flightType]: data } = this.state;
    const modData = { ...data };
    modData[field] = {
      value: item[accessor],
      valid: true,
      item,
    };
    this.setState({ [flightType]: modData });
  };

  renderTickets = () => {
    const { documents } = this.state;
    if (documents.length === 0) {
      return null;
    }
    return (
      <DocumentCards
        documents={documents}
        onRemove={this.handleRemove}
      />
    );
  };

  validateFlightData = (data) => {
    const { source } = this.state;
    const {
      from, to, dateVerified,
      noOfStops, cabinClass,
    } = data;
    let valid = true;
    let errorMsg = '';
    if (!from.valid) {
      valid = false;
      errorMsg = 'Invalid departure place';
    } else if (!to.valid) {
      valid = false;
      errorMsg = 'Invalid arrival at place';
    } else if (noOfStops === '') {
      valid = false;
      errorMsg = 'Flight stops cannot be empty';
    } else if (!cabinClass.valid && source === 'FLYNOTE') {
      valid = false;
      errorMsg = 'Invalid cabin class';
    } else if (!dateVerified) {
      valid = false;
      errorMsg = 'Flight date not verified';
    }
    // else if (flightNumber === '') {
    //   valid = false;
    //   errorMsg = 'Flight number cannot be empty';
    // } else if (terminal === '') {
    //   valid = false;
    //   errorMsg = 'Terminal cannot be empty';
    // }
    // else if (!invoiceId.trim().length) {
    //   valid = false;
    //   errorMsg = 'Flight Invoice ID is required';
    // }
    return {
      valid,
      errorMsg,
    };
  };

  validityChecker = (setError) => {
    const {
      flightType, departureFlightData, returnFlightData,
      costPrice, sellingPrice, supplier, baggage, source,
      travellers,
    } = this.state;
    const { expert } = this.props;
    let valid = true;
    let errorMsg = '';
    const departureValidation = this.validateFlightData(departureFlightData);
    if (!departureValidation.valid) {
      valid = false;
      errorMsg = departureValidation.errorMsg;
    }
    if (flightType === 'Round trip') {
      const returnValidation = this.validateFlightData(returnFlightData);
      if (!returnValidation.valid) {
        valid = false;
        errorMsg = returnValidation.errorMsg;
      }
    }
    if (source === 'FLYNOTE') {
      if (Number(costPrice) === 0) {
        valid = false;
        errorMsg = 'Invalid Cost price';
      } else if (Number(sellingPrice) === 0) {
        valid = false;
        errorMsg = 'Invalid Selling price';
      } else if (!supplier.valid && expert.roles.includes(ROLES.TEAM_LEAD)) {
        valid = false;
        errorMsg = 'Invalid supplier';
      } else if (!baggage.valid) {
        valid = false;
        errorMsg = 'Invalid baggage option';
      }
    }
    if (!(travellers.adults || 0)) {
      valid = false;
      errorMsg = 'Check the travellers';
    }
    if (setError) {
      this.setState({
        valid,
        errorMsg,
      });
    } else {
      this.setState({ valid });
    }
    return valid;
  };

  submitFlightData = (data) => {
    const {
      from, to, flightDate, flightNumber, invoiceId,
      terminal, noOfStops, cabinClass, pnr,
    } = data;
    return {
      from: from.item,
      to: to.item,
      flightDate: moment(flightDate).toISOString(),
      pnr,
      flightNumber,
      terminal,
      noOfStops,
      cabinClass: cabinClass.value,
      invoiceId,
    };
  };

  onSubmit = () => {
    const {
      onSubmit, flight = {},
      isEdit, onDismiss,
    } = this.props;
    const {
      flightType, departureFlightData, returnFlightData, documents, refundable, cancellationAmount,
      baggage, costPrice, sellingPrice, supplier, travellers, notes, valid, source,
    } = this.state;
    if (!isEdit) {
      onDismiss();
      return;
    }
    if (!valid) {
      this.validityChecker(true);
      return;
    }
    const params = {
      ...flight,
      flightType,
      departureFlightData: this.submitFlightData(departureFlightData),
      returnFlightData: flightType === 'Round trip' ? this.submitFlightData(returnFlightData) : undefined,
      documents,
      baggage: baggage.value,
      isRefundable: refundable === 'Refundable',
      cancellationAmount: refundable === 'Refundable' ? cancellationAmount : undefined,
      supplier,
      costPrice: costPrice * 100,
      sellPrice: sellingPrice * 100,
      travellers,
      notes,
      source,
    };
    onSubmit(params);
  };

  renderFlightRow = (item) => {
    const { classes } = this.props;
    const { airport = {}, name, parent = {} } = item;
    const { iata = '-' } = airport;
    const { name: location = '-' } = parent;
    return (
      <div className={classes.flightRow}>
        <div className={classes.col}>
          <Typography className={classes.airportName}>{name}</Typography>
          <Typography className={classes.airportLocation}>{location || '-'}</Typography>
        </div>
        <Typography className={classes.airportCode}>{iata}</Typography>
      </div>
    );
  };

  renderFlightData = (flightType) => {
    const { classes, suggestions } = this.props;
    const { [flightType]: flightData, source } = this.state;
    const titleText = flightType === 'returnFlightData' ? 'Round trip' : 'One way';
    return (
      <div className={classes.content}>
        <Typography className={classes.title}>{`${titleText} details`}</Typography>
        <div className={classes.row}>
          <Autocomplete
            label="FROM"
            required
            value={flightData.from.value}
            data={suggestions.airports}
            accessor="name"
            extraClass={classes.left}
            inputClass={classes.autocompleteInput}
            customRender={this.renderFlightRow}
            onChange={(val) => this.handleFlightDataChange(flightType, 'from', val)}
            onSelected={(item) => this.handleFlightDataSelection(flightType, 'from', item, 'name')}
          />
          <Autocomplete
            label="TO"
            required
            value={flightData.to.value}
            data={suggestions.airports}
            accessor="name"
            extraClass={classes.right}
            inputClass={classes.autocompleteInput}
            customRender={this.renderFlightRow}
            onChange={(val) => this.handleFlightDataChange(flightType, 'to', val)}
            onSelected={(item) => this.handleFlightDataSelection(flightType, 'to', item, 'name')}
          />
        </div>
        <div className={classes.row}>
          <div className={classes.colLeft}>
            <Typography className={classes.label}>FLIGHT DATE & TIME</Typography>
            <ButtonBase
              className={classes.dateContainer}
            >
              <DateTimePicker
                disablePast
                value={flightData.flightDate}
                className={classes.datePicker}
                onChange={(date) => this.handleFlightDataChange(flightType, 'flightDate', date)}
              />
              {moment(flightData.flightDate).format('DD MMM YY hh:mm A')}
              <CalendarIcon className={classes.calendarImg} />
            </ButtonBase>
          </div>
          <LabelInput
            label="FLIGHT NO."
            value={flightData.flightNumber}
            extraClass={classes.right}
            onChange={(val) => this.handleFlightDataChange(flightType, 'flightNumber', val)}
          />
        </div>
        <div className={classes.row}>
          <LabelInput
            label="TERMINAL NO."
            value={flightData.terminal}
            extraClass={classes.left}
            onChange={(val) => this.handleFlightDataChange(flightType, 'terminal', val)}
          />
          <div className={classes.colRight}>
            <Typography className={classes.label}>NO. OF STOPS</Typography>
            <div className={classes.chipsContainer}>
              {this.stops.map((stop) => {
                const selected = flightData.noOfStops === stop;
                return (
                  <Chip
                    label={stop}
                    key={stop}
                    className={clsx(classes.chip, selected && classes.selectedChip)}
                    onClick={() => this.handleFlightDataChange(flightType, 'noOfStops', stop)}
                    variant="outlined"
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className={classes.row}>
          <LabelInput
            label="PNR"
            value={flightData.pnr}
            extraClass={classes.left}
            onChange={(val) => this.handleFlightDataChange(flightType, 'pnr', val)}
          />
          {source === 'FLYNOTE' ? (
            <Autocomplete
              label="CABIN CLASS"
              required
              value={flightData.cabinClass.value}
              data={this.cabins}
              accessor="name"
              extraClass={classes.right}
              inputClass={classes.autocompleteInput}
              onChange={(val) => this.handleFlightDataChange(flightType, 'cabinClass', val)}
              onSelected={(item) => this.handleFlightDataSelection(flightType, 'cabinClass', item, 'name')}
            />
          ) : <div className={classes.right} />}
        </div>
        <div className={classes.row}>
          <div className={classes.left}>
            <LabelInput
              label="Invoice Id"
              value={flightData.invoiceId}
              extraClass={classes.left}
              onChange={(val) => this.handleFlightDataChange(flightType, 'invoiceId', val)}
            />
          </div>
          <div className={classes.right} />
        </div>
      </div>
    );
  };

  render() {
    const {
      classes, onDismiss, version,
      suggestions, itineraryActionType, expert,
    } = this.props;
    const {
      flightType, baggage, travellers, costPrice, refundable, cancellationAmount,
      sellingPrice, supplier, notes, valid, errorMsg, edit, showDiff, source, autoFillInProcess,
    } = this.state;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DragnDrop
          extraClass={classes.dragContainer}
          fileDropHandler={this.handleDrop}
        >
          <div className={clsx(classes.container, version === 2 && classes.newContainer)}>
            <Header
              className={classes.headerStrip}
              titleClass={classes.titleClass}
              variant="back"
              img={arrowBack}
              title="Add Flight"
              onDismiss={onDismiss}
              showDiffToggle={this.showDiffToggle}
              checked={this.showDiffToggle && showDiff}
              label="VIEW CHANGES"
              onToggle={this.toggleDiff}
            />
            {showDiff ? <FlightDiff oldData={this.originalFlight} data={this.state} />
              : (
                <div className={classes.body} style={{ position: 'relative' }}>
                  {
                    autoFillInProcess
                      ? (
                        <div
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0,0,0,0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'start',
                            zIndex: 1,
                            paddingTop: 50,
                          }}
                        >
                          <div
                            style={{
                              color: '#fff',
                              fontSize: '20px',
                            }}
                          >
                            Auto fill from image in progress ...
                          </div>
                        </div>
                      )
                      : null
                  }
                  {source === 'CUSTOMER' ? (
                    <Typography className={classes.customerFlight}>
                      FLIGHT BOOKED BY CUSTOMER
                    </Typography>
                  ) : null}
                  <div className={classes.wrapper}>
                    <DotLine />
                    <div className={classes.content}>
                      <Typography className={classes.title}>Upload documents (Optional)</Typography>
                      <div className={classes.dropArea}>
                        <input
                          type="file"
                          accept=".pdf, .png, .jpg, .jpeg"
                          ref={this.dropInput}
                          className={classes.dropBox}
                          onChange={this.handleUpload}
                        />
                        <Typography
                          className={classes.uploadText}
                        >
                          Upload flight tickets
                        </Typography>
                        {this.renderTickets()}
                      </div>
                    </div>
                  </div>
                  <div className={classes.wrapper}>
                    <DotLine />
                    <div style={{ marginLeft: 30 }}>
                      <div
                        style={{
                          marginLeft: -10,
                          color: '#333',
                          fontFamily: 'Lato',
                          fontSize: 16,
                          marginBottom: 10,
                        }}
                      >
                        Trip Type
                      </div>
                      <RadioOptions
                        options={this.flightTypes}
                        selected={flightType}
                        onChange={(e) => this.handleChange('flightType', e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={classes.wrapper}>
                    <DotLine />
                    {this.renderFlightData('departureFlightData')}
                  </div>
                  {flightType === 'Round trip' ? (
                    <div className={classes.wrapper}>
                      <DotLine />
                      {this.renderFlightData('returnFlightData')}
                    </div>
                  ) : null}
                  {source === 'FLYNOTE' ? (
                    <div className={classes.wrapper}>
                      <DotLine />
                      <div className={classes.content}>
                        <Typography className={classes.title}>Other details</Typography>
                        <div className={classes.row}>
                          <Autocomplete
                            label="BAGGAGE"
                            value={baggage.value}
                            data={this.baggages}
                            accessor="name"
                            extraClass={classes.left}
                            inputClass={classes.autocompleteInput}
                            onChange={(val) => this.handleChange('baggage', val)}
                            onSelected={(item) => this.handleSelection('baggage', item, 'name')}
                          />
                          <TravellerSelection
                            extraClass={classes.right}
                            label="TRAVELLERS"
                            travellers={travellers}
                            onChange={(val) => this.handleChange('travellers', val)}
                          />
                        </div>
                        <div className={classes.row}>
                          <RadioOptions
                            options={['Refundable', 'Non-refundable']}
                            selected={refundable}
                            extraClass={classes.radioGroup}
                            onChange={(e) => this.handleChange('refundable', e.target.value)}
                          />
                          {refundable === 'Refundable' ? (
                            <LabelInput
                              value={cancellationAmount}
                              extraClass={classes.right}
                              label="MAX CANCELLATION CHARGE"
                              inputProps={{
                                type: 'number',
                              }}
                              onChange={(val) => this.handleChange('cancellationAmount', val)}
                            />
                          ) : null}
                        </div>
                        <div className={classes.row}>
                          <LabelInput
                            extraClass={classes.left}
                            value={costPrice}
                            label="COST PRICE"
                            inputProps={{
                              type: 'number',
                            }}
                            onChange={(val) => this.handleChange('costPrice', val)}
                          />
                          <LabelInput
                            extraClass={classes.right}
                            value={sellingPrice}
                            label="SELLING PRICE"
                            inputProps={{
                              type: 'number',
                            }}
                            onChange={(val) => this.handleChange('sellingPrice', val)}
                          />
                        </div>
                        {
                          expert.roles.includes(ROLES.TEAM_LEAD)
                            ? (
                              <div className={classes.row}>
                                <Autocomplete
                                  label="SUPPLIER"
                                  value={supplier.value}
                                  data={suggestions.suppliers}
                                  accessor="name"
                                  extraClass={classes.left}
                                  inputClass={classes.autocompleteInput}
                                  onChange={(val) => this.handleSupplierChange(val)}
                                  onSelected={(item) => this.handleSupplierSelection(item)}
                                />
                              </div>
                            )
                            : null
                        }
                      </div>
                    </div>
                  ) : null}
                  {source === 'FLYNOTE' ? (
                    <div className={classes.wrapper}>
                      <DotLine />
                      <div className={classes.content}>
                        <LabelInput
                          inputProps={{
                            multiline: true,
                            rows: 3,
                            rowsMax: 6,
                          }}
                          inputClass={classes.notesInput}
                          extraClass={classes.notes}
                          label="NOTES"
                          placeholder="Details about the airlines, preferred travel time, etc."
                          value={notes}
                          onChange={(val) => this.handleChange('notes', val)}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            <Footer errorMsg={errorMsg}>
              <Button
                className={clsx(classes.createButton, !valid && classes.disabled)}
                onClick={this.onSubmit}
              >
                {getButtonText(itineraryActionType, edit, 'flight')}
              </Button>
            </Footer>
          </div>
        </DragnDrop>
      </MuiPickersUtilsProvider>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: 940,
    height: 562,
    maxHeight: 562,
    borderRadius: 10,
    backgroundColor: theme.colors.white,
    overflowY: 'auto',
  },
  newContainer: {
    height: 562,
    maxHeight: '100vh',
    overflowY: 'visible',
  },
  dragContainer: {
    height: 562,
  },
  headerStrip: {
    boxSizing: 'border-box',
    height: 40,
    backgroundColor: theme.colors.primaryLight,
  },
  titleClass: {
    fontSize: 14,
    fontWeight: 'normal',
    color: theme.colors.textDark,
  },
  body: {
    padding: '20px 40px',
    boxSizing: 'border-box',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  customerFlight: {
    fontSize: 14,
    color: theme.colors.textDark,
    fontWeight: 'bold',
    margin: '0 auto 16px',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  autoFillInProcess: {

  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 30,
    paddingLeft: 20,
  },
  title: {
    fontSize: 16,
    color: theme.colors.textDark,
    letterSpacing: 0.5,
    marginBottom: 20,
  },
  left: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginRight: 10,
  },
  right: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginLeft: 10,
  },
  radioGroup: {
    flex: 1,
    margin: '10px 0 0 10px',
  },
  returnSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: 20,
  },
  colRight: {
    // marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginLeft: 10,
  },
  colLeft: {
    flex: 1,
    // marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 10,
  },
  label: {
    color: theme.colors.textLight,
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  checkboxRoot: {
    marginBottom: 6,
  },
  checkboxLabel: {
    fontSize: 10,
  },
  checkbox: {
    '& > span > svg': {
      height: '20px !important',
      width: '20px !important',
    },
  },
  row: {
    flexWrap: 'wrap',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  autocompleteContainer: {
    flex: 1,
    maxHeight: 72,
    marginBottom: 20,
  },
  autocompleteInput: {
    width: '100%',
    height: '100%',
    padding: '5px 15px',
  },
  flightRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  col: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  airportCode: {
    textAlign: 'center',
    fontSize: 14,
    width: 80,
    color: theme.colors.textDark,
    fontWeight: 'bold',
  },
  airportName: {
    fontSize: 14,
    color: theme.colors.black,
    marginBottom: 6,
  },
  airportLocation: {
    fontSize: 12,
    color: theme.colors.textDark,
  },
  labelDateContainer: {
    display: 'flex',
    flex: 1,
    marginBottom: 20,
  },
  dateContainer: {
    // flex: 1,
    width: '100%',
    outline: 'none',
    border: `1px solid ${theme.colors.border}`,
    color: theme.colors.black,
    fontSize: 14,
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
    borderRadius: 4,
    height: 40,
    padding: '5px 10px',
    minWidth: 90,
    position: 'relative',
    justifyContent: 'flex-start',
  },
  calendarImg: {
    marginLeft: 'auto',
  },
  datePicker: {
    opacity: 0,
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    cursor: 'pointer',
  },
  dropArea: {
    width: '50%',
    height: 110,
    position: 'relative',
    border: `1px dashed ${theme.colors.primaryBackground}`,
    backgroundColor: 'rgba(234,242,242,0.4)',
    padding: 20,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 30,
    cursor: 'pointer',
  },
  dropBox: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
  },
  uploadText: {
    fontSize: 14,
    color: theme.colors.primary,
    alignSelf: 'center',
  },
  chipsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  chip: {
    marginRight: 10,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
    '&:focus': {
      backgroundColor: `${theme.colors.primaryLight}`,
    },
    '&:active': {
      backgroundColor: `${theme.colors.primaryLight}`,
    },
  },
  selectedChip: {
    border: `1px solid ${theme.colors.primary}`,
    backgroundColor: theme.colors.primaryLight,
  },
  notes: {
    width: '100%',
  },
  notesInput: {
    height: 'auto',
  },
  createButton: {
    width: 180,
    color: theme.colors.white,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
    backgroundColor: theme.colors.primaryBackground,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  disabled: {
    cursor: 'pointer !important',
    color: `${theme.colors.white} !important`,
    backgroundColor: theme.colors.grey,
    '&:hover': {
      backgroundColor: theme.colors.grey,
    },
  },
});

CreateFlight.propTypes = {
  classes: PropTypes.object,
  onDismiss: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  suggestions: PropTypes.object.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  flight: PropTypes.object,
  originalFlight: PropTypes.object,
  itineraryActionType: PropTypes.string.isRequired,
  version: PropTypes.number,
  isEdit: PropTypes.bool.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  expert: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreateFlight);
