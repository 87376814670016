import { hot } from 'react-hot-loader/root';
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Dialog from '@material-ui/core/Dialog';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import PlaceIcon from '@material-ui/icons/PlaceOutlined';
import StayIcon from '@material-ui/icons/LocalHotelOutlined';
import ExperienceIcon from '@material-ui/icons/LocalActivityOutlined';
import TransferIcon from '@material-ui/icons/LocalTaxiOutlined';
import FlightIcon from '@material-ui/icons/Flight';
import ServicesIcon from '@material-ui/icons/LibraryAdd';
import InclusionExclusionIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import PricingIcon from '@material-ui/icons/LocalOfferOutlined';
import CalendarIcon from '@material-ui/icons/CalendarTodayOutlined';
import PersonIcon from '@material-ui/icons/Person';
import SupplierIcon from '@material-ui/icons/EmojiPeopleOutlined';
import RoutePreview from 'components/itineraryMaker/routePreview';
import InclusionExclusion from 'components/itineraryMaker/inclusionExclusion';
import Pricing from 'components/itineraryMaker/pricing';
import StayPreview from 'components/itineraryMaker/stayPreview';
import TransfersPreview from 'components/itineraryMaker/transfersPreview';
import ActivityPreview from 'components/itineraryMaker/activityPreview';
import FlightsPreview from 'components/itineraryMaker/flightsPreview';
import ServicesPreview from 'components/itineraryMaker/servicesPreview';
import Supplier from 'components/itineraryMaker/supplier';
import DotLine from 'UI/dotLine';
import Confirmation from 'UI/confirmation';
import Footer from 'UI/footer';
import Button from 'UI/button';
import DocumentSuccessIcon from 'assets/icons/documentSuccess';
import {
  CHANGE_TYPES,
  ITINERARY_ACTIONS_TYPES,
  ITINERARY_READ_MODES,
  ROLES,
} from 'utils/consts';
import {
  cleanInclusionExclusion,
  convertRequirement,
  debounce,
  deepClone,
  getItineraryParts,
  getTravellerText,
  headerGenerator,
  isEqual,
  mapItineraryParts,
  processPricingForSubmission,
  processRequirement,
  uuid,
} from 'utils/common';
import AddTripRequirement from 'components/dashboard/addTripRequirement';
import Loader from 'UI/loader';

class ItineraryMaker extends Component {
  constructor(props) {
    super(props);
    const { token, expert } = this.props;
    this.headers = headerGenerator(token);
    this.sections = ['maker'];
    this.tabs = {
      maker: ['Trip details', 'Route', 'Stay', 'Experience', 'Transfers', 'Flights', 'Services',
        'Inclusion/exclusion', 'Pricing'],
    };
    this.iconMap = {
      'Trip details': ListAltOutlinedIcon,
      Route: PlaceIcon,
      Stay: StayIcon,
      Experience: ExperienceIcon,
      Transfers: TransferIcon,
      Suppliers: SupplierIcon,
      Flights: FlightIcon,
      Services: ServicesIcon,
      'Inclusion/exclusion': InclusionExclusionIcon,
      Pricing: PricingIcon,
    };
    this.itineraryActionType = '';
    this.activeRequest = {};
    this.itineraryID = undefined;
    this.bookingIndex = -1;
    this.getSetActionType();
    // let changes = {};
    let activeTab = '';
    if (this.itineraryActionType === 'template' && this.activeRequest.itineraries) {
      activeTab = this.tabs.maker[0];
    }
    // else if (this.itineraryActionType === 'edit' && this.activeRequest.itineraries) {
    //   const activeItinerary = this.activeRequest.itineraries
    //     .find((i) => i._id === this.itineraryID);
    //   if (activeItinerary) {
    //     changes = {
    //       ...changes,
    //       ...this.processItineraryPayload(activeItinerary),
    //     };
    //   } else {
    //     window.location.href = `${window.location.origin}/itineraryMaker/request/
    //     ${this.activeRequest.usId}`;
    //   }
    // }
    if (_.isEmpty(_.intersection(
      expert.roles, [ROLES.ADMIN, ROLES.TEAM_LEAD, ROLES.OPERATION, ROLES.FLIGHT]
    ))) {
      this.tabs.maker = this.tabs.maker
        .filter((tab) => (tab !== 'Flights' && tab !== 'Services'));
    }
    if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING
      || this.itineraryActionType === ITINERARY_ACTIONS_TYPES.VIEW_BOOKING
      || this.itineraryActionType === ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY
      || this.itineraryActionType === ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY_TECH) {
      this.tabs.maker.splice(1, 0, 'Suppliers');
    } else if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.ACCEPT_BOOKING) {
      this.tabs.maker = ['Suppliers'];
    } else if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.CONFIRM_BOOKING) {
      this.tabs.maker = ['Suppliers', 'Stay', 'Services'];
    }
    // if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING) {
    //   this.tabs.maker.push('Token');
    // }
    if (expert.roles.includes(ROLES.OPERATION) && !expert.roles.includes(ROLES.TEAM_LEAD)
      && !expert.roles.includes(ROLES.FELLOW) && !expert.roles.includes(ROLES.EXPERT)) {
      this.tabs.maker = ['Route', 'Flights', 'Services', 'Pricing'];
    }
    this.originalItinerary = {};
    this.originalParts = {};
    this.originalPrice = 0;
    this.state = {
      isFetchingRooms: false,
      activeSection: 'maker',
      activeTab,
      requirement: {},
      identifier: 1,
      routes: [],
      itineraryParts: [],
      pricing: {},
      exclusions: [{
        id: uuid(),
        text: '',
      }],
      inclusions: [{
        id: uuid(),
        text: '',
      }],
      suppliers: [],
      errorMsg: {},
      deleteConfirmation: false,
      sendConfirmationDialog: false,
      itinerarySendSuccess: false,
      sendWithSellingPriceDialog: false,
      bookingConfirmationDialog: false,
      deleteIndex: -1,
      allowSellPrice: true,
      b2bPartnerOrderAmount: '',
      tags: [],
      submitting: false,
      // ...changes,
    };
  }

  componentDidUpdate = (prevProps) => {
    const {
      meta,
      history,
      showSnackbar,
      requestById,
      isGettingRequestByID,
      getRequestByIDError,
      getRequestByIDErrorMsg,
      saveItineraryDraftResp,
      saveItineraryDraftError,
      saveItineraryDraftErrorMsg,
      isGettingStayRooms,
      getStayRoomsResp,
      getStayRoomsError,
      getStayRoomsErrorMsg,
      itineraryPriceResp,
      itineraryPriceError,
      itineraryPriceErrorMsg,
      updateStatusResp,
      updateStatusError,
      updateStatusErrorMsg,
      bookingActionResp,
      bookingActionError,
      bookingActionErrorMsg,
    } = this.props;
    const { activeSection, activeTab } = this.state;
    if (!isGettingRequestByID && this.itineraryActionType !== 'template'
      && !isEqual(requestById, prevProps.requestById)) {
      console.log('itineraryMaker 1');
      this.activeRequest = requestById;
      const changes = {};
      if (!this.itineraryID) {
        changes.requirement = processRequirement(this.activeRequest.requirement, meta.tripTypes);
      }
      this.setState({
        // requirement: processRequirement(this.activeRequest.requirement),
        activeTab: this.tabs[activeSection][0],
        ...changes,
      });
    } else if (!isEqual(getRequestByIDError, prevProps.getRequestByIDError)
      && getRequestByIDError) {
      console.log('itineraryMaker 2');
      showSnackbar(getRequestByIDErrorMsg, 'error');
    }
    if (!activeTab && !isGettingRequestByID && !_.isEmpty(requestById)) {
      console.log('itineraryMaker 3');
      this.activeRequest = requestById;
      let changes = {};
      if (this.itineraryActionType !== '' && this.itineraryActionType !== 'new') {
        const activeItinerary = this.activeRequest.itineraries
          .find((i) => i._id === this.itineraryID);
        if ([
          ITINERARY_ACTIONS_TYPES.VIEW_BOOKING,
          ITINERARY_ACTIONS_TYPES.ACCEPT_BOOKING,
          ITINERARY_ACTIONS_TYPES.CONFIRM_BOOKING].includes(this.itineraryActionType)) {
          console.log('itineraryMaker 4');
          const {
            itinerary: itn,
            itineraryParts: iParts,
          } = getItineraryParts(this.activeRequest, this.activeRequest.bookings[this.bookingIndex]._id);
          const modItn = {
            ...itn,
            itineraryParts: iParts,
          };
          changes = {
            ...changes,
            ...this.processItineraryPayload(modItn),
            requirement: processRequirement(activeItinerary.requirement, meta.tripTypes),
            activeTab: this.tabs[activeSection][0],
          };
        } else if (activeItinerary) {
          console.log('itineraryMaker 4');
          this.activeItinerary = this.processItineraryPayload(activeItinerary);
          this.validateItineraryAction();
          if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY) {
            this.originalItinerary = deepClone(this.activeItinerary);
            for (let i = 0; i < this.originalItinerary.itineraryParts.length; i++) {
              const tag = uuid();
              const proposedChange = {
                tag,
                changeType: CHANGE_TYPES.NO_CHANGE,
              };
              this.originalItinerary.itineraryParts[i].proposedChange = proposedChange;
              this.activeItinerary.itineraryParts[i].proposedChange = proposedChange;
              this.originalParts[tag] = this.originalItinerary.itineraryParts[i];
            }
          }
          console.log('itineraryMaker 5');
          changes = {
            ...this.activeItinerary,
          };
          if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.APPROVE_REJECT_ITINERARY) {
            console.log('itineraryMaker 6');
            // marking itinerary parts
            const processedItn = this.processItineraryPayload(activeItinerary.proposedItinerary);
            const {
              finalParts,
              originalParts,
            } = mapItineraryParts(processedItn, this.activeItinerary);
            this.originalParts = originalParts;
            processedItn.itineraryParts = finalParts;
            changes = {
              ...changes,
              requirement: processRequirement(activeItinerary.proposedItinerary.requirement,
                meta.tripTypes),
              ...processedItn,
            };
          }
        } else {
          window.location.href = `${window.location.origin}/itineraryMaker/request/${this.activeRequest.usId}`;
        }
      }
      if (!this.itineraryID) {
        console.log('itineraryMaker 7');
        changes.requirement = processRequirement(this.activeRequest.requirement, meta.tripTypes);
      }

      console.log('itineraryMaker 8');
      this.setState({
        activeTab: this.tabs[activeSection][0],
        ...changes,
      }, () => {
        this.originalPrice = this.getOriginalPricing();
      });
    }
    if (!isEqual(updateStatusError, prevProps.updateStatusError) && updateStatusError) {
      showSnackbar(updateStatusErrorMsg, 'error');
    } else if (!isEqual(updateStatusResp, prevProps.updateStatusResp) && updateStatusResp) {
      const newItinerary = updateStatusResp.request?.itineraries
        .find((i) => i._id === this.itineraryID);
      if (newItinerary && newItinerary.status === 'QUOTATION_REQUESTED') {
        this.itineraryActionType = 'view';
      }
      if (newItinerary?.status === 'ITINERARY_SENT') {
        showSnackbar('Itinerary has been sent successfully', 'success');
        history.push(`/request/${this.activeRequest.usId}?followup=true`);
      } else {
        showSnackbar(updateStatusResp.message, 'success');
        if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING
          || this.itineraryActionType === ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY
          || this.itineraryActionType === ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY_TECH) {
          history.push(`/request/${this.activeRequest.usId}`);
        }
      }
    }
    if (!isEqual(bookingActionResp, prevProps.bookingActionResp) && bookingActionResp.request) {
      showSnackbar(bookingActionResp.message || 'Booking updated successfully', 'success');
      history.push(`/request/${this.activeRequest.usId}`);
    } else if (!isEqual(bookingActionError, prevProps.bookingActionError) && bookingActionError) {
      showSnackbar(bookingActionErrorMsg, 'error');
    }
    if (!isEqual(itineraryPriceError, prevProps.itineraryPriceError) && itineraryPriceError) {
      showSnackbar(itineraryPriceErrorMsg, 'error');
    } else if (!isEqual(itineraryPriceResp, prevProps.itineraryPriceResp)
      && itineraryPriceResp.priceAvailable !== undefined) {
      console.log('itineraryMaker 9');
      this.setState({ pricing: itineraryPriceResp });
    }
    if (!isEqual(getStayRoomsError, prevProps.getStayRoomsError)
      && getStayRoomsError) {
      showSnackbar(getStayRoomsErrorMsg, 'error');
      console.log('itineraryMaker 10');
      this.setState({ isFetchingRooms: false });
    } else if (!isGettingStayRooms && !isEqual(getStayRoomsResp, prevProps.getStayRoomsResp)
      && getStayRoomsResp.rooms.length) {
      console.log('itineraryMaker 11');
      const { itineraryParts } = this.state;
      const modParts = this.mapStayPartsToRooms(itineraryParts, getStayRoomsResp.rooms);
      this.setState({
        isFetchingRooms: false,
        itineraryParts: modParts,
      });
    }
    if (!isEqual(saveItineraryDraftError, prevProps.saveItineraryDraftError)
      && saveItineraryDraftError) {
      showSnackbar(saveItineraryDraftErrorMsg, 'error');
    } else if (!isEqual(saveItineraryDraftResp, prevProps.saveItineraryDraftResp)
      && (saveItineraryDraftResp.message || saveItineraryDraftResp.request)) {
      if (!this.itineraryID && saveItineraryDraftResp.request.draftedItineraryId) {
        this.itineraryID = saveItineraryDraftResp.request.draftedItineraryId;
        this.activeItinerary = saveItineraryDraftResp.request
          .find((i) => i._id === this.itineraryID);
        history.push(`${window.location.pathname}/${this.itineraryID}`);
      }
      // if (activeTab === 'Pricing') {
      //   history.push(`/request/${this.activeRequest.usId}`);
      // }
      showSnackbar('Itinerary saved successfully', 'success');
    }
  };

  getSetActionType = () => {
    const { getRequestById } = this.props;
    const pathSplits = window.location.pathname.split('/');
    if (pathSplits[pathSplits.length - 1] === '') {
      pathSplits.pop();
    }
    if (pathSplits.length === 4 && pathSplits[2] === 'request') {
      this.itineraryActionType = 'new';
      getRequestById({ usId: pathSplits[3] }, this.headers);
    } else if (pathSplits.length === 5 && pathSplits[2] === 'request' && pathSplits[4].length) {
      this.itineraryActionType = 'edit';
      this.itineraryID = pathSplits[4];
      getRequestById({ usId: pathSplits[3] }, this.headers);
    } else if (pathSplits.length === 6 && pathSplits[2] === 'request' && pathSplits[4].length) {
      if (pathSplits[5] === 'view') {
        this.itineraryActionType = 'view';
      } else if (pathSplits[5] === 'booking') {
        this.itineraryActionType = ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING;
      } else if (pathSplits[5] === 'modify') {
        this.itineraryActionType = 'modify_itinerary';
      } else if (pathSplits[5] === 'modify_tech') {
        this.itineraryActionType = 'modify_itinerary_tech';
      } else if (pathSplits[5] === 'approve') {
        this.itineraryActionType = 'approve_reject_itinerary';
      }
      this.itineraryID = pathSplits[4];
      getRequestById({ usId: pathSplits[3] }, this.headers);
    } else if (pathSplits.length === 7) {
      if (pathSplits[5] === 'confirm_booking') {
        this.itineraryActionType = 'confirm_booking';
      } else if (pathSplits[5] === 'accept_booking') {
        this.itineraryActionType = 'accept_booking';
      } else if (pathSplits[5] === 'view_booking') {
        this.itineraryActionType = 'view_booking';
      }
      this.itineraryID = pathSplits[4];
      this.bookingIndex = Number(pathSplits[6]);
      getRequestById({ usId: pathSplits[3] }, this.headers);
    } else {
      this.itineraryActionType = 'template';
    }
  };

  getSuggestions = debounce((type, query, extras) => {
    const { getAutocompleteSuggestions } = this.props;
    const params = {
      skip: 0,
      limit: 5,
      query,
      type,
      ...extras,
    };
    getAutocompleteSuggestions(params, this.headers);
  }, 300);

  validateItineraryAction = () => {
    const activeItinerary = this.activeRequest.itineraries.find((i) => i._id === this.itineraryID);
    if (activeItinerary.status === 'QUOTATION_REQUESTED') {
      this.itineraryActionType = 'view';
    }
    if (this.activeRequest.status === 'APPROVED' && this.itineraryActionType === 'edit') {
      window.location.href = `/itineraryMaker/request/${this.activeRequest.usId}/modify`;
    }
    if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING && activeItinerary.status === 'DRAFTED') {
      window.location.href = `/itineraryMaker/request/${this.activeRequest.usId}/${this.itineraryID}`;
      this.itineraryActionType = 'edit';
    }
    // else if (this.activeItinerary.status === 'DRAFTED'
    // && this.itineraryActionType === 'modify_itinerary') {
    //   window.location.href = `${window.location.origin}/itineraryMaker
    //   /request/${this.activeRequest.usId}/${this.itineraryID}`;
    // }
  };

  getOriginalPricing = () => {
    const { pricing } = this.state;
    return pricing.finalSellPrice * 100;
  };

  mapStayPartsToRooms = (parts, rooms) => {
    const modParts = [...parts];
    const roomsMap = {};
    const stayRooms = {};
    for (let i = 0; i < rooms.length; i++) {
      roomsMap[rooms[i]._id] = rooms[i];
      if (stayRooms[rooms[i].stay]) {
        stayRooms[rooms[i].stay].push(rooms[i]);
      } else {
        stayRooms[rooms[i].stay] = [rooms[i]];
      }
    }
    for (let i = 0; i < modParts.length; i++) {
      const part = modParts[i];
      if (part.type === 'STAY') {
        part.roomDetails = part.roomDetails.map((roomDetail) => {
          return {
            ...roomDetail,
            // room: roomsMap[roomDetail.room],
          };
        });
        part.rooms = stayRooms[part.stay._id];
      }
      modParts[i] = part;
    }
    return modParts;
  };

  processItineraryPayload = (respItinerary) => {
    const { getStayRooms, meta: { transfers, tripTypes } } = this.props;
    const clonedItn = deepClone(respItinerary);
    const routeMap = {};
    const itineraryParts = [];
    const inclusions = (respItinerary.inclusions || []).map((i) => ({
      ...i,
      id: i._id,
    })) || [{
      id: uuid(),
      value: '',
    }];
    const exclusions = (respItinerary.exclusions || []).map((e) => ({
      ...e,
      id: e._id,
    })) || [{
      id: uuid(),
      value: '',
    }];
    const transferMap = {};
    for (let i = 0; i < transfers.length; i++) {
      transferMap[transfers[i].code] = transfers[i];
    }
    const routes = (clonedItn.routes || []).map((route, index) => {
      const modRoute = {
        id: uuid(),
        duration: route.duration || 1,
        index,
        dates: {
          from: moment(route.startTimeStamp).toDate(),
          to: moment(route.endTimeStamp).toDate(),
        },
        place: {
          value: route.place.name,
          valid: true,
          item: route.place,
        },
      };
      routeMap[route.place._id] = modRoute;
      return modRoute;
    });
    const stayRoomsToGet = {};
    for (let i = 0; i < (clonedItn.itineraryParts || []).length; i++) {
      let modPart = {};
      const part = clonedItn.itineraryParts[i];
      const partKey = `${part.type.toLowerCase()}Data`;
      if (part.type === 'STAY') {
        modPart = {
          ...part,
          ...part.stayData,
          transferMode: part.stayData.transferMode?.map((tm) => transferMap[tm]) || [],
          route: routeMap[part.stayData.routeId],
          routeIndex: part.stayData.routeIndex,
        };
        if (!stayRoomsToGet[part.stayData.stay._id]) {
          stayRoomsToGet[part.stayData.stay._id] = true;
        }
      }
      if (part.type === 'TRANSFER') {
        modPart = {
          ...part,
          ...part.transferData,
        };
      }
      if (part.type === 'ACTIVITY') {
        const changes = {};
        if (part.activityData.transferMode) {
          changes.transferMode = transferMap[part.activityData.transferMode];
        }
        modPart = {
          ...part,
          ...part.activityData,
          ...changes,
        };
        // FIXME: get it corrected from backend
        if (part.activityData.experience.experience) {
          modPart = {
            ...part,
            ...part.activityData,
            experience: {
              ...part.activityData.experience.experience,
              _id: part.activityData.experience._id,
            },
            ...changes,
          };
        }
      }
      if (part.type === 'FLIGHT' || part.type === 'SERVICE') {
        modPart = {
          ...part,
          ...part[partKey],
          supplier: {
            value: part.supplier.name,
            valid: true,
            item: part.supplier,
          },
        };
      }
      if (!modPart.supplier) {
        modPart.supplier = {
          value: '',
          item: {},
          valid: false,
        };
      } else {
        modPart.supplier = {
          value: part.supplier.name,
          item: part.supplier,
          valid: true,
        };
      }
      itineraryParts.push(modPart);
      delete modPart[partKey];
    }
    const stayIds = Object.keys(stayRoomsToGet);
    if (stayIds.length) {
      getStayRooms({ stayIds }, this.headers);
      this.setState({ isFetchingRooms: true });
    }
    const pricing = this.processPricing(respItinerary.pricing || {});
    return {
      requirement: processRequirement(respItinerary.requirement, tripTypes),
      tokenAmount: (respItinerary.tokenAmount || 500000) / 100,
      routes,
      itineraryParts,
      inclusions,
      exclusions,
      pricing,
      identifier: respItinerary.identifier,
      tags: (respItinerary.tags || []),
      suppliers: (clonedItn.suppliers || []).map((supplier) => ({
        ...supplier,
        costPrice: supplier.costPrice / 100,
      })),
    };
  };

  processPricing = (respPricing) => {
    const pricing = {
      landPackage: {
        costPrice: 0,
        sellPrice: 0,
        margin: 0,
      },
      flights: {
        costPrice: 0,
        sellPrice: 0,
        margin: 0,
      },
      services: {
        costPrice: 0,
        sellPrice: 0,
        margin: 0,
      },
      totalSellPrice: 0,
      discount: 0,
      finalSellPrice: 0,
    };
    if (Object.keys(respPricing).length === 0) {
      return pricing;
    }
    const convertToRupees = (item = {}) => {
      return {
        costPrice: (item.costPrice || 0) / 100,
        sellPrice: (item.sellPrice || 0) / 100,
        margin: (item.margin || 0) / 100,
      };
    };
    pricing.landPackage = convertToRupees(respPricing.landPackage);
    pricing.flights = convertToRupees(respPricing.flights);
    pricing.services = convertToRupees(respPricing.services);
    pricing.totalSellPrice = (respPricing.totalSellPrice || 0) / 100;
    pricing.discount = (respPricing.discount || 0) / 100;
    pricing.finalSellPrice = (respPricing.finalSellPrice || 0) / 100;
    return pricing;
  };

  validateTab = (tab) => {
    const {
      itineraryParts,
      // installments,
      tokenAmount,
      b2bPartnerOrderAmount,
      routes,
      pricing,
    } = this.state;
    let valid = true;
    const errorMsg = {};
    const mode = (ITINERARY_READ_MODES[this.itineraryActionType]
      && this.itineraryActionType !== ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING) ? 'view' : 'edit';
    // extra check for Stays while confirm booking
    if (valid && tab === 'Stay' && this.itineraryActionType === ITINERARY_ACTIONS_TYPES.CONFIRM_BOOKING) {
      for (let i = 0; i < itineraryParts.length; i++) {
        const part = itineraryParts[i];
        if (part.type === 'STAY') {
          if (!part.customerRefNum || part.customerRefNum.trim() === '') {
            valid = false;
            errorMsg[tab] = 'Reference number is missing from some stay(s)';
            break;
          }
          // for (let j = 0; j < part.roomDetails.length; j++) {
          //   if (!part.roomDetails[j].customerRefNum
          //     || part.roomDetails[j].customerRefNum.trim() === '') {
          //     valid = false;
          //     errorMsg[tab] = 'Reference number is missing from some room(s)';
          //     break;
          //   }
          // }
        }
      }
    }
    if (valid && tab === 'Stay'
      && (
        this.itineraryActionType === ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY
        || this.itineraryActionType === ITINERARY_ACTIONS_TYPES.CONFIRM_BOOKING
        || this.itineraryActionType === ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING
      )
    ) {
      for (let i = 0; i < itineraryParts.length; i++) {
        const part = itineraryParts[i];
        if (part.type === 'STAY' && !(part.supplier.item && part.supplier.value && part.supplier.valid)) {
          valid = false;
          errorMsg[tab] = 'Supplier not selected for stay';
          break;
        }
      }
    }
    if (valid && tab === 'Pricing' && pricing.landPackage.sellPrice < pricing.landPackage.costPrice) {
      valid = false;
      errorMsg[tab] = 'SP cannot be less than CP';
    }
    if (valid && (tab === 'Pricing') && !pricing.landPackage.costPrice && !pricing.flights.costPrice && !pricing.services.costPrice) {
      valid = false;
      errorMsg[tab] = 'CP cannot be 0';
    }
    if (valid && (tab === 'Pricing') && (tokenAmount < 500000)) {
      valid = false;
      errorMsg[tab] = 'Token amount cannot be less than ₹5000';
    }
    if (valid && (tab === 'Pricing') && (tokenAmount > _.sum([pricing.landPackage.sellPrice, pricing.flights.sellPrice, pricing.services.sellPrice]) * 100)) {
      valid = false;
      errorMsg[tab] = 'Token amount cannot be more than SP';
    }
    // if (valid && (tab === 'Pricing') && (tokenAmount < installments[0].amount)) {
    //   valid = false;
    //   errorMsg[tab] = `Token amount cannot be less than ₹${_.round(installments[0].amount/100)}`;
    // }
    if (valid && tab === 'Pricing'
      && (
        this.itineraryActionType === ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING
        && this.activeRequest?.b2bPartner
        && !b2bPartnerOrderAmount
      )
    ) {
      valid = false;
      errorMsg[tab] = 'Cred amount is not filled';
    }
    if (mode === 'view') {
      return {
        valid,
        errorMsg,
      };
    }
    switch (tab) {
      // case 'Suppliers': {
      //   if ((!suppliers || !suppliers.length) && (
      //     this.itineraryActionType === ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY
      //     || this.itineraryActionType === ITINERARY_ACTIONS_TYPES.CONFIRM_BOOKING
      //     || this.itineraryActionType === ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING
      //   )) {
      //     valid = false;
      //     errorMsg[tab] = 'Supplier not selected';
      //     break;
      //   }
      //   break;
      // }
      case 'Route': {
        for (let i = 0; i < routes.length; i++) {
          const route = routes[i];
          if (!route.place.valid) {
            valid = false;
            errorMsg[tab] = 'Incomplete route details';
            break;
          }
          if (this.itineraryActionType !== 'template' && (!route.dates.from || !route.dates.to)) {
            valid = false;
            errorMsg[tab] = 'Route dates not selected';
            break;
          }
        }
        break;
      }
      default:
        break;
    }
    return {
      valid,
      errorMsg,
    };
  };

  tabChangeHandler = (tab) => {
    const { activeSection, activeTab } = this.state;
    if (tab !== activeTab) {
      const targetIndex = this.tabs[activeSection].indexOf(tab);
      const currentIndex = this.tabs[activeSection].indexOf(activeTab);
      if (targetIndex > currentIndex) {
        // validating all tabs in between as well
        for (let i = currentIndex; i < targetIndex; i++) {
          const { valid, errorMsg } = this.validateTab(this.tabs[activeSection][i]);
          if (!valid) {
            this.setState({
              errorMsg,
              activeTab: this.tabs[activeSection][i],
            });
            return;
          }
        }
      }
      this.setState({ activeTab: tab });
    }
  };

  handleChange = (field, value) => {
    const { tags } = this.state;
    if (field === 'datesAreTentative') {
      const index = (tags || []).indexOf('DATES_NOT_FINAL');
      if (index === -1) {
        this.setState({
          tags: [...(tags || []), 'DATES_NOT_FINAL'],
        });
      } else {
        const modData = [...tags];
        modData.splice(index, 1);
        this.setState({
          tags: modData,
        });
      }
    } else {
      this.setState({ [field]: value });
    }
  };

  dismissDeleteConfirmation = () => {
    this.setState({
      deleteConfirmation: false,
      deleteIndex: -1,
    });
  };

  deleteConfirmation = () => {
    const { itineraryParts, deleteIndex } = this.state;
    const modParts = [...itineraryParts];
    if (this.itineraryActionType === 'modify_itinerary') {
      const currTag = modParts[deleteIndex].proposedChange.tag;
      const foundIndex = this.getPartIndexFromTag(currTag);
      if (foundIndex !== -1) {
        this.originalItinerary
          .itineraryParts[foundIndex].proposedChange.changeType = CHANGE_TYPES.DELETE;
        modParts[deleteIndex].proposedChange.changeType = CHANGE_TYPES.DELETE;
      } else {
        modParts.splice(deleteIndex, 1);
      }
    } else {
      modParts.splice(deleteIndex, 1);
    }
    this.setState({
      itineraryParts: modParts,
      deleteConfirmation: false,
      deleteIndex: -1,
    });
  };

  getPartIndexFromTag = (tag) => {
    let foundIndex = -1;
    for (let i = 0; i < this.originalItinerary.itineraryParts.length; i++) {
      if (this.originalItinerary.itineraryParts[i].proposedChange.tag === tag) {
        foundIndex = i;
        break;
      }
    }
    return foundIndex;
  };

  handleItemChange = (action, index, value) => {
    const { itineraryParts } = this.state;
    const mode = ITINERARY_READ_MODES[this.itineraryActionType] ? 'view' : 'edit';
    let modParts = [...itineraryParts];
    if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.CONFIRM_BOOKING && value.type === 'STAY') {
      modParts[index] = value;
      this.setState({ itineraryParts: modParts });
      return;
    }
    if (mode === 'view' && this.itineraryActionType !== ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING) {
      return;
    }
    const finalValue = { ...value };
    if (action === 'add') {
      let finalIndex = -1;
      const requestedTime = moment(value.startTimeStamp).unix();
      for (let i = 0; i < modParts.length; i++) {
        const item = modParts[i];
        const time = moment(item.startTimeStamp).unix();
        if (requestedTime <= time) {
          finalIndex = i;
          break;
        }
      }
      if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY) {
        finalValue.proposedChange = {
          changeType: CHANGE_TYPES.ADD,
          tag: uuid(),
        };
      }
      if (finalIndex === -1) {
        modParts.push(finalValue);
      } else {
        modParts = [
          ...modParts.slice(0, finalIndex),
          finalValue,
          ...modParts.slice(finalIndex),
        ];
      }
    } else if (action === 'edit') {
      if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY) {
        // mark update in original as well
        const currTag = finalValue.proposedChange.tag;
        const foundIndex = this.getPartIndexFromTag(currTag);
        if (currTag !== CHANGE_TYPES.DELETE) {
          if (foundIndex !== -1) {
            this.originalItinerary.itineraryParts[foundIndex].proposedChange = {
              tag: currTag,
              changeType: CHANGE_TYPES.EDIT,
            };
            finalValue.proposedChange.changeType = CHANGE_TYPES.EDIT;
          }
        }
      }
      modParts[index] = finalValue;
    } else if (action === 'remove') {
      this.setState({
        deleteConfirmation: true,
        deleteIndex: index,
        errorMsg: {},
      });
    }
    this.setState({ itineraryParts: modParts, errorMsg: {} });
  };

  nextHandler = () => {
    const { history } = this.props;
    const { activeSection, activeTab } = this.state;
    const { valid, errorMsg } = this.validateTab(activeTab);
    if (!valid) {
      this.setState({ errorMsg });
      return;
    }
    const sectionIndex = this.sections.indexOf(activeSection);
    const tabIndex = this.tabs[activeSection].indexOf(activeTab);
    if (tabIndex === this.tabs[activeSection].length - 1) {
      // last tab - check for next section else submit itinerary
      if (sectionIndex === this.sections.length - 1) {
        if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.VIEW_BOOKING
          || this.itineraryActionType === ITINERARY_ACTIONS_TYPES.VIEW_ITINERARY) {
          history.push(`/request/${this.activeRequest.usId}`);
          return;
        }
        this.handleSendToCustomer();
      } else {
        this.submitHandler();
        const newSection = this.sections[sectionIndex + 1];
        this.setState({
          activeTab: this.tabs[newSection][0],
          activeSection: newSection,
        });
      }
    } else {
      // tabs are still left
      this.setState({ activeTab: this.tabs[activeSection][tabIndex + 1] });
      this.submitHandler();
    }
  };

  processPartsForSubmission = (parts) => {
    const itineraryParts = [];
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i];
      const {
        startTimeStamp,
        endTimeStamp,
        supplier,
        type,
        _id,
        // eslint-disable-next-line camelcase
        created_at,
        status,
        proposedChange,
        ...extraData
      } = part;
      const key = `${part.type.toLowerCase()}Data`;
      const partToPush = {
        [key]: extraData,
        startTimeStamp,
        endTimeStamp,
        type,
        _id,
        created_at,
        status,
      };
      if (supplier?.item?._id) {
        partToPush.supplier = supplier.item._id;
      }
      if (part.type === 'STAY') {
        delete partToPush.stayData.rooms;
        delete partToPush.stayData.aggregatedProfiles;
        partToPush.stayData.transferMode = partToPush.stayData?.transferMode
          .map((t) => t.code) || [];
        partToPush.stayData.stay = partToPush.stayData.stay._id;
        partToPush.stayData.routeId = partToPush.stayData.route.place.item._id;
        partToPush.stayData.roomDetails = partToPush.stayData.roomDetails.map((room) => {
          const {
            mealPlan, roomCheckInDate,
            roomCheckOutDate, occupancy, room: r,
            customerRefNum,
          } = room;
          return {
            customerRefNum,
            room: r._id,
            mealPlan,
            roomCheckInDate,
            roomCheckOutDate,
            occupancy,
          };
        });
        delete partToPush.stayData.route;
      }
      if (part.type === 'TRANSFER') {
        partToPush.transferData.transfers = partToPush.transferData.transfers.map((t) => t._id);
      }
      if (part.type === 'ACTIVITY') {
        partToPush.activityData.experience = partToPush.activityData.experience._id;
        if (partToPush.activityData.from?.name && partToPush.activityData.transferMode?.title) {
          partToPush.activityData.from = partToPush.activityData.from._id;
          partToPush.activityData.to = partToPush.activityData.to._id;
          partToPush.activityData.transferMode = partToPush.activityData.transferMode.code;
        }
        console.log('partToPush.activityData ', partToPush._id, partToPush);
      }
      if (part.type === 'FLIGHT' || part.type === 'SERVICE') {
        partToPush.costPrice = part.costPrice;
        partToPush.sellPrice = part.sellPrice;
        partToPush.sellCurrency = '5879fb9a14f89e036eb59550';
        partToPush.costCurrency = '5879fb9a14f89e036eb59550';
        partToPush.supplierCurrency = '5879fb9a14f89e036eb59550';
      }
      if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY) {
        partToPush.proposedChange = proposedChange;
      }
      itineraryParts.push(partToPush);
    }
    return itineraryParts;
  };

  checkSupplierTaggingValidity = () => {
    const { itineraryParts, suppliers } = this.state;
    const supplierMap = {};
    for (let i = 0; i < suppliers.length; i++) {
      supplierMap[suppliers[i].supplier._id] = {
        hasPart: false,
        name: suppliers[i].supplier.name,
      };
    }
    let error = false;
    let errorMsg = '';
    for (let i = 0; i < itineraryParts.length; i++) {
      const part = itineraryParts[i];
      if (part.type !== 'FLIGHT' && part.type !== 'SERVICE') {
        if (!part.supplier.item?._id) {
          error = true;
          errorMsg = 'Itinerary parts are not tagged to supplier';
          break;
        }
        if (part.supplier.item?._id && !supplierMap[part.supplier.item?._id]) {
          error = true;
          errorMsg = 'Itinerary part tagged to removed supplier';
          break;
        }
        supplierMap[part.supplier.item._id].hasPart = true;
      }
    }
    if (error) {
      return { error, errorMsg };
    }
    const modSuppliers = Object.values(supplierMap);
    for (let i = 0; i < modSuppliers.length; i++) {
      if (!modSuppliers[i].hasPart) {
        error = true;
        errorMsg = 'Suppliers not tagged to itinerary parts';
        break;
      }
    }
    return { error, errorMsg };
  };

  getPriceWithFlightsAndServices = () => {
    const { pricing } = this.state;
    return pricing;
    // const { pricing, itineraryParts } = this.state;
    // let flightsCP = 0;
    // let flightsSP = 0;
    // let servicesCP = 0;
    // let servicesSP = 0;
    // for (let i = 0; i < itineraryParts.length; i++) {
    //   if (itineraryParts[i].type === 'FLIGHT') {
    //     flightsCP += itineraryParts[i].costPrice;
    //     flightsSP += itineraryParts[i].sellPrice;
    //   }
    //   if (itineraryParts[i].type === 'SERVICE') {
    //     servicesCP += itineraryParts[i].costPrice;
    //     servicesSP += itineraryParts[i].sellPrice;
    //   }
    // }
    // const modPricing = {
    //   ...pricing,
    //   flights: {
    //     sellPrice: flightsSP,
    //     costPrice: flightsCP,
    //   },
    //   services: {
    //     sellPrice: servicesSP,
    //     costPrice: servicesCP,
    //   },
    // };
    // return modPricing;
  };

  submitHandler = (draft = true, approvalAction) => {
    if (!draft) {
      const { submitting } = this.state;
      if (submitting) {
        return;
      }
    }
    this.setState({ bookingConfirmationDialog: false });
    const activeItinerary = this.activeRequest.itineraries
      .find((i) => i._id === this.itineraryID);
    const canUpdateItinerary = activeItinerary.status !== 'QUOTATION_REQUESTED'
      && this.itineraryActionType !== 'view';
    if (!canUpdateItinerary) {
      return;
    }
    if (activeItinerary.status !== 'DRAFTED' && draft) {
      return;
    }
    const { bookingAction } = this.props;
    const {
      suppliers: bookingSuppliers,
      activeTab, tags, b2bPartnerOrderAmount,
      installments,
      tokenAmount,
    } = this.state;
    if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING) {
      const { error, errorMsg } = this.checkSupplierTaggingValidity();
      if (!draft && error) {
        const eMsg = { [activeTab]: errorMsg };
        this.setState({ errorMsg: eMsg });
        return;
      }
    }
    if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.ACCEPT_BOOKING
      || this.itineraryActionType === ITINERARY_ACTIONS_TYPES.CONFIRM_BOOKING) {
      if (draft) {
        return;
      }
      const {
        _id,
      } = this.activeRequest.bookings[this.bookingIndex];
      const {
        supplier, costPrice, currency, quotation,
        supplierRefNum, conversionRate = 1,
      } = bookingSuppliers[0] || this.activeRequest.bookings[this.bookingIndex];
      const params = {
        supplier: {
          supplier: supplier?._id,
          costPrice: Number(costPrice || 0) * 100,
          quotation,
          supplierRefNum,
          currency: currency._id,
          conversionRate,
        },
        bookingId: _id,
      };
      if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.CONFIRM_BOOKING) {
        const { itineraryParts } = this.state;
        const parts = this.processPartsForSubmission(deepClone(itineraryParts));
        params.itineraryParts = parts;
      }
      bookingAction({
        bookingParams: {
          ...params,
          requestId: this.activeRequest._id,
        },
        type: this.itineraryActionType,
        requestId: this.activeRequest._id,
      }, this.headers);
      return;
    }
    const { saveItineraryDraft, updateStatus } = this.props;
    const stateData = deepClone(this.state);
    const {
      itineraryParts, routes, suppliers,
      inclusions, exclusions, requirement,
      pricing,
    } = stateData;
    const parts = deepClone(itineraryParts);
    console.log('parts ', parts);
    let params = {
      details: {
        requirement: convertRequirement(requirement),
        routes: routes.map((route) => ({
          startTimeStamp: moment(route.dates.from),
          endTimeStamp: moment(route.dates.to),
          duration: route.duration,
          routeIndex: undefined,
          place: route.place.item._id,
        })),
        itineraryParts: this.processPartsForSubmission(parts),
        pricing: processPricingForSubmission(pricing),
        installments,
        tokenAmount,
        inclusions: cleanInclusionExclusion(inclusions),
        exclusions: cleanInclusionExclusion(exclusions),
        itineraryVersion: 2,
        b2bPartnerOrderAmount: Number(b2bPartnerOrderAmount) * 100,
        tags,
      },
      requestId: this.activeRequest._id,
    };
    if (this.itineraryID) {
      params.details.itineraryId = this.itineraryID;
    }
    if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING
      || ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY_TECH
      || ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY) {
      params.details.suppliers = suppliers.map((s) => ({
        supplier: s.supplier._id,
        costPrice: Number(s.costPrice) * 100,
        quotation: s.quotation,
        currency: s.currency._id,
        conversionRate: s.conversionRate,
      }));
      params.details.landPackageBookingDate = moment().endOf('day').toISOString();
    }
    if (draft) {
      saveItineraryDraft(params, this.headers);
    } else {
      let action = 'SEND_ITINERARY';
      if (this.itineraryActionType === 'modify_itinerary') {
        action = 'MODIFY_BOOKING';
      } else if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING) {
        action = 'REQUEST_BOOKING';
      } else if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.APPROVE_REJECT_ITINERARY) {
        action = `${approvalAction ? 'APPROVE' : 'REJECT'}_BOOKING_MODIFICATION`;
        params = {
          details: {
            itineraryId: this.itineraryID,
            itineraryVersion: 2,
          },
        };
      }
      updateStatus({
        action,
        _id: this.activeRequest._id,
        details: params.details,
      }, this.headers);
    }
    if (draft) {
      // this.setState({ pricing: {} });
    } else {
      this.setState({ submitting: true });
      setTimeout(() => this.setState({ submitting: false }), 1000);
    }
  };

  dashboardHandler = () => {
    const { history } = this.props;
    history.replace('/dashboard');
  };

  renderItineraryDetails = () => {
    const { classes } = this.props;
    const {
      requirement: {
        tripDate, places, travellers = {},
      },
      routes = [],
      identifier,
    } = this.state;
    const { primaryCustomer, primaryCustomerData } = this.activeRequest;
    const userDetails = primaryCustomer || primaryCustomerData || {};
    const {
      firstName, lastName,
    } = userDetails;
    let destinations = '';
    if (this.itineraryActionType !== 'new' && routes.length && routes[0].valid) {
      destinations = routes.map((r) => {
        return `${r.place.item.name} ${r.duration}N`;
      }).join(' - ');
    } else {
      destinations = places?.items.map((p) => p.name).join(' - ');
    }
    return (
      <div className={classes.userContainer}>
        <Typography className={classes.userName}>
          {`${firstName} ${lastName}`}
        </Typography>
        <div className={classes.userRow}>
          <div className={classes.userSection}>
            <Typography className={classes.userTravelInfo}>
              {`Itinerary ${identifier}`}
            </Typography>
          </div>
          <div className={classes.userSection}>
            <CalendarIcon className={classes.userInfoIcon} />
            <Typography className={classes.userTravelInfo}>
              {tripDate}
            </Typography>
          </div>
          <div className={classes.userSection}>
            <PlaceIcon className={classes.userInfoIcon} />
            <Typography className={classes.userTravelInfo}>
              {destinations}
            </Typography>
          </div>
          <div className={classes.userSection}>
            <PersonIcon className={classes.userInfoIcon} />
            <Typography className={classes.userTravelInfo}>
              {getTravellerText(travellers)}
            </Typography>
          </div>
        </div>
      </div>
    );
  };

  renderTabs = () => {
    const { classes } = this.props;
    const { activeSection, activeTab } = this.state;
    const tabsLength = this.tabs[activeSection].length;
    return this.tabs[activeSection].map((tab, index) => {
      const isLastTab = (tabsLength === (index + 1));
      const isActive = (activeTab === tab);
      const tabText = ((tab === 'Suppliers') ? 'Land Suppliers' : tab);
      return (
        <ButtonBase
          disableRipple
          key={tab}
          className={classes.tabContainer}
          onClick={() => this.tabChangeHandler(tab)}
        >
          <DotLine
            dotClass={isActive ? undefined : classes.dotClass}
            lineClass={clsx(
              isLastTab ? classes.lastDot : '',
              isActive && classes.activeDot
            )}
            isLast={isLastTab}
            DotIcon={isActive ? this.iconMap[tab] : undefined}
          />
          <Typography
            className={clsx(classes.tab, isActive && classes.activeTab)}
          >
            {tabText}
          </Typography>
        </ButtonBase>
      );
    });
  };

  handleRequirementChange = (tripDetails) => {
    const { requirement } = this.state;
    if (tripDetails.tags) {
      this.setState({ tags: tripDetails.tags });
      return;
    }

    const { itineraryParts, routes } = this.state;
    const startDeltaDays = moment(tripDetails.tripDates.from)
      .diff(moment(requirement.tripDates.from), 'days');

    const modRoutes = _.compact(routes.map((route) => {
      let modRoute = _.cloneDeep(route);
      modRoute.dates = {
        from: this.updateDate(route.dates.from, startDeltaDays),
        to: this.updateDate(route.dates.to, startDeltaDays),
      };

      if (moment(modRoute.dates.from).isSameOrAfter(moment(tripDetails.tripDates.to), 'day')) {
        // this route is post the trip details end date, remove this
        modRoute = null;
      } else if (moment(modRoute.dates.to).isAfter(moment(tripDetails.tripDates.to), 'day')) {
        // this route is ending post the trip details end date, shorten this
        modRoute.dates.to = tripDetails.tripDates.to;
      }

      return modRoute;
    }));

    const modParts = _.compact(itineraryParts.map((itineraryPart) => {
      let modPart = _.cloneDeep(itineraryPart);
      modPart.startTimeStamp = this.updateDate(modPart.startTimeStamp, startDeltaDays);
      modPart.endTimeStamp = this.updateDate(modPart.endTimeStamp, startDeltaDays);

      if (modPart.type === 'TRANSFER') {
        modPart.transferDate = modPart.endTimeStamp;
      } else if (modPart.type === 'STAY') {
        modPart.checkInDate = modPart.startTimeStamp;
        modPart.checkOutDate = modPart.endTimeStamp;
        modPart.roomDetails = modPart.roomDetails.map((roomData) => {
          const nRoomData = _.cloneDeep(roomData);
          nRoomData.roomCheckInDate = this.updateDate(roomData.roomCheckInDate, startDeltaDays);
          nRoomData.roomCheckOutDate = this.updateDate(roomData.roomCheckOutDate, startDeltaDays);
          return nRoomData;
        });
      }

      if (moment(modPart.startTimeStamp).isAfter(moment(tripDetails.tripDates.to), 'day')) {
        // this part is post the trip details end date, remove this
        modPart = null;
      } else if (moment(modPart.startTimeStamp).isSame(moment(tripDetails.tripDates.to), 'day')) {
        if (modPart.type === 'STAY') {
          // this stay checkin is on the last day, remove this
          modPart = null;
        }
      } else if (moment(modPart.endTimeStamp).isAfter(moment(tripDetails.tripDates.to), 'day')) {
        // this part is ending post the trip details end date, shorten this
        modPart.endTimeStamp = tripDetails.tripDates.to;

        if (modPart.type === 'TRANSFER') {
          modPart.transferDate = modPart.endTimeStamp;
        } else if (modPart.type === 'STAY') {
          modPart.checkOutDate = modPart.endTimeStamp;
          modPart.roomDetails = _.compact(modPart.roomDetails.map((roomData) => {
            let nRoomData = _.cloneDeep(roomData);
            nRoomData.roomCheckInDate = this.updateDate(roomData.roomCheckInDate, startDeltaDays);
            nRoomData.roomCheckOutDate = this.updateDate(roomData.roomCheckOutDate, startDeltaDays);

            if (moment(nRoomData.roomCheckInDate).isSameOrAfter(moment(tripDetails.tripDates.to), 'day')) {
              nRoomData = null;
            } else if (moment(nRoomData.roomCheckOutDate).isAfter(moment(tripDetails.tripDates.to), 'day')) {
              nRoomData.roomCheckOutDate = modPart.endTimeStamp;
            }

            return nRoomData;
          }));
        }
      }
      return modPart;
    }));

    this.setState({
      requirement: tripDetails,
      routes: modRoutes,
      itineraryParts: modParts,
    });
  };

  requestPrice = (type) => {
    const { getItineraryPrice, updateStatus } = this.props;
    if (type === 'pricing') {
      getItineraryPrice({ itineraryId: this.itineraryID }, this.headers);
    } else if (type === 'quotation') {
      const {
        routes, itineraryParts, requirement,
        inclusions, exclusions,
      } = this.state;
      const parts = deepClone(itineraryParts);
      const clonedRoutes = deepClone(routes);
      updateStatus({
        _id: this.activeRequest._id,
        action: 'REQUEST_QUOTATION',
        details: {
          requirement: convertRequirement(deepClone(requirement)),
          itineraryId: this.itineraryID,
          routes: clonedRoutes.map((route) => ({
            startTimeStamp: moment(route.dates.from),
            endTimeStamp: moment(route.dates.to),
            duration: route.duration,
            routeIndex: route.routeIndex,
            place: route.place.item._id,
          })),
          itineraryParts: this.processPartsForSubmission(parts),
          inclusions,
          exclusions,
        },
      }, this.headers);
    }
  };

  sendItinerary = () => {
    const { updateStatus } = this.props;
    updateStatus({
      _id: this.activeRequest._id,
      action: 'SEND_ITINERARY',
      details: {
        itineraryId: this.itineraryID,
      },
    }, this.headers);
  };

  getConversion = (params) => {
    const { getConversion } = this.props;
    getConversion(params, this.headers);
  };

  handleItinerarySendConfirmation = (action) => {
    const { activeTab, pricing } = this.state;
    if (action === 'reject') {
      if (!pricing.landPackage?.sellPrice || Number(pricing.landPackage?.sellPrice) <= 0) {
        this.setState({ errorMsg: { [activeTab]: 'Enter sell pricing' }, sendConfirmationDialog: false });
      } else {
        this.setState({ sendWithSellingPriceDialog: false });
      }
    } else if (action === 'accept') {
      this.submitHandler(false);
      this.setState({ sendWithSellingPriceDialog: false });
    }
  };

  handleSendToCustomer = () => {
    const { pricing, allowSellPrice } = this.state;
    if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING) {
      this.setState({ bookingConfirmationDialog: true });
      return;
    }
    if (this.itineraryActionType === 'accept_booking' || this.itineraryActionType === 'confirm_booking') {
      this.submitHandler(false);
      return;
    }
    if (Object.keys(pricing).length === 0 || (pricing.priceAvailable === false && !allowSellPrice)
      || !pricing.landPackage?.sellPrice) {
      this.setState({ sendConfirmationDialog: true });
    } else if (pricing.landPackage && pricing.landPackage.sellPrice
      && Number(pricing.landPackage.sellPrice) >= 0) {
      this.setState({ sendWithSellingPriceDialog: true });
    }
  };

  handleSendConfirmation = (action) => {
    if (action === 'send_without_price') {
      this.setState({ sendConfirmationDialog: false }, () => { // pricing: {}
        this.submitHandler(false);
      });
    } else if (action === 'send_with_price') {
      this.submitHandler(false);
    }
  };

  updateDate = (date, daysToAdd) => {
    return moment(date).add(daysToAdd, 'days').toDate();
  };

  renderActiveTab = () => {
    const {
      suggestions, meta, expert, showSnackbar,
      isFetchingSuggestions, conversion, classes,
      requestById,
    } = this.props;
    const {
      activeTab, routes, requirement, inclusions, suppliers,
      exclusions, itineraryParts, errorMsg, pricing,
      tags, b2bPartnerOrderAmount,
    } = this.state;
    const activeItinerary = (this.activeRequest?.itineraries || []).find((i) => i._id === this.itineraryID);
    const mode = (this.itineraryActionType === 'edit'
      || this.itineraryActionType === 'modify_itinerary'
      || this.itineraryActionType === ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING) ? 'edit' : 'view';
    let minDate;
    if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.EDIT && this.activeRequest.status === 'TOKEN_PAID') {
      if (activeItinerary?.status === 'DRAFTED') {
        const dates = this.activeRequest.itineraries
          .filter((itn) => itn.status === 'APPROVED' && itn.itineraryParts.some((part) => part.type === 'STAY'))
          .map((itn) => moment(itn.requirement.departureDate));
        minDate = new Date(Math.max(...dates)); // Min date for new landPackage
      }
    }

    switch (activeTab) {
      case 'Route':
        return (
          <RoutePreview
            showSnackbar={showSnackbar}
            routes={routes}
            requirement={requirement}
            itineraryParts={itineraryParts}
            getSuggestions={this.getSuggestions}
            suggestions={suggestions}
            datesAreTentative={(tags || []).includes('DATES_NOT_FINAL')}
            onUpdate={this.handleChange}
            itineraryActionType={this.itineraryActionType}
          />
        );
      case 'Stay':
        return (
          <StayPreview
            itineraryActionType={this.itineraryActionType}
            itineraryParts={itineraryParts}
            errorMsg={errorMsg[activeTab]}
            requirement={requirement}
            originalParts={this.originalParts}
            mode={mode}
            suppliers={suppliers}
            routes={routes}
            minDate={minDate}
            transferModes={meta.transfers}
            meta={meta}
            getSuggestions={this.getSuggestions}
            suggestions={suggestions}
            isFetchingSuggestions={isFetchingSuggestions}
            onUpdate={this.handleItemChange}
            version={2}
          />
        );
      case 'Experience':
        return (
          <ActivityPreview
            requirement={requirement}
            experienceCategories={meta.experienceCategories}
            itineraryActionType={this.itineraryActionType}
            originalParts={this.originalParts}
            mode={mode}
            suppliers={suppliers}
            tripDates={requirement.tripDates}
            transferModes={meta.transfers}
            routes={routes}
            itineraryParts={itineraryParts}
            getSuggestions={this.getSuggestions}
            suggestions={suggestions}
            isFetchingSuggestions={isFetchingSuggestions}
            errorMsg={errorMsg[activeTab]}
            onUpdate={this.handleItemChange}
            version={2}
          />
        );
      case 'Transfers':
        return (
          <TransfersPreview
            itineraryActionType={this.itineraryActionType}
            originalParts={this.originalParts}
            mode={mode}
            suppliers={suppliers}
            tripDates={requirement.tripDates}
            travellers={requirement.travellers}
            transferModes={meta.transfers}
            routes={routes}
            itineraryParts={itineraryParts}
            getSuggestions={this.getSuggestions}
            suggestions={suggestions}
            errorMsg={errorMsg[activeTab]}
            onUpdate={this.handleItemChange}
            version={2}
          />
        );
      case 'Suppliers': {
        const places = requirement.places.items.map((d) => d._id);
        return (
          <Supplier
            itineraryActionType={this.itineraryActionType}
            errorMsg={errorMsg[activeTab]}
            conversion={conversion}
            getConversion={this.getConversion}
            suppliers={suppliers}
            suggestions={suggestions}
            places={places}
            onUpdate={this.handleChange}
            getSuggestions={this.getSuggestions}
            pricing={pricing}
          />
        );
      }
      case 'Trip details': {
        return (
          <AddTripRequirement
            itineraryActionType={this.itineraryActionType}
            errorMsg={errorMsg[activeTab]}
            mode={mode}
            meta={meta}
            showRemarks={false}
            showFooter={false}
            extraClass={classes.tripRequirement}
            tags={tags}
            suggestions={suggestions}
            tripDetails={requirement}
            getSuggestions={this.getSuggestions}
            onUpdate={this.handleRequirementChange}
          />
        );
      }
      case 'Flights':
        return (
          <FlightsPreview
            itineraryActionType={this.itineraryActionType}
            mode={mode}
            routes={routes}
            suppliers={suppliers}
            tripDates={requirement.tripDates}
            itineraryParts={itineraryParts}
            originalParts={this.originalParts}
            getSuggestions={this.getSuggestions}
            suggestions={suggestions}
            errorMsg={errorMsg[activeTab]}
            onUpdate={this.handleItemChange}
            version={2}
            showSnackbar={showSnackbar}
            headers={this.headers}
            expert={expert}
          />
        );
      case 'Services':
        return (
          <ServicesPreview
            itineraryActionType={this.itineraryActionType}
            mode={mode}
            getSuggestions={this.getSuggestions}
            suggestions={suggestions}
            errorMsg={errorMsg[activeTab]}
            itineraryParts={itineraryParts}
            originalParts={this.originalParts}
            onUpdate={this.handleItemChange}
            version={2}
          />
        );
      case 'Inclusion/exclusion':
        return (
          <InclusionExclusion
            itineraryActionType={this.itineraryActionType}
            mode={mode}
            inclusions={inclusions}
            exclusions={exclusions}
            errorMsg={errorMsg[activeTab]}
            onUpdate={this.handleChange}
          />
        );
      case 'Pricing': {
        /*
        const ORIGINAL_PAGE = {
          [ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING]: true,
          [ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY]: true,
          [ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY_TECH]: true,
          [ITINERARY_ACTIONS_TYPES.VIEW_BOOKING]: true,
          [ITINERARY_ACTIONS_TYPES.APPROVE_REJECT_ITINERARY]: true,
          [ITINERARY_ACTIONS_TYPES.VIEW_ITINERARY]: true,
        };
        if (ORIGINAL_PAGE[this.itineraryActionType]) {
         */
        return (
          <Pricing
            itineraryActionType={this.itineraryActionType}
            mode={mode}
            itineraryParts={itineraryParts}
            suppliers={suppliers}
            pricing={pricing}
            isB2bBooking={!!this.activeRequest.b2bPartner}
            b2bPartnerOrderAmount={b2bPartnerOrderAmount}
            originalPricing={this.originalItinerary.pricing}
            errorMsg={errorMsg[activeTab]}
            conversion={conversion}
            getConversion={this.getConversion}
            onUpdate={this.handleChange}
            headers={this.headers}
            routes={routes}
            expert={expert}
            version={2}
            showSnackbar={showSnackbar}
            installments={activeItinerary.installments}
            request={requestById}
          />
        );
        /*
        }
        return (
          <PricingNew
            itineraryActionType={this.itineraryActionType}
            requestPrice={() => this.requestPrice('pricing')}
            requestQuotation={() => this.requestPrice('quotation')}
            onUpdate={this.handleChange}
            expert={expert}
            pricing={pricing}
            allowSellPrice={allowSellPrice}
          />
        );
        */
      }
      // case 'Token': {
      //   return (
      //     <TokenAmount
      //       onUpdate={(value) => this.handleChange('tokenAmount', value)}
      //       tokenAmount={tokenAmount}
      //     />
      //   );
      // }
      default:
        return null;
    }
  };

  render() {
    const {
      classes, isGettingStayRooms, isUpdatingStatus,
      isGettingRequestByID, isBooking,
    } = this.props;
    const {
      deleteConfirmation, errorMsg, itinerarySendSuccess, pricing,
      activeTab, isFetchingRooms, sendConfirmationDialog,
      sendWithSellingPriceDialog, bookingConfirmationDialog,
    } = this.state;
    const isLoading = isGettingStayRooms || isGettingRequestByID
      || isFetchingRooms || isBooking || isUpdatingStatus;
    // isLoading = false;
    let buttonText = ((activeTab === 'Pricing') ? 'Send to customer' : 'Save & continue');
    if (ITINERARY_READ_MODES[this.itineraryActionType]) {
      if (activeTab === 'Pricing') {
        buttonText = 'Close';
      } else {
        buttonText = 'Next';
      }
    }
    let saveButtonText = 'Save as draft';
    if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING && activeTab === 'Pricing') {
      saveButtonText = 'Save';
      buttonText = 'Request booking';
    }
    if (this.itineraryActionType === ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY
      || this.itineraryActionType === ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY_TECH) {
      saveButtonText = '';
      buttonText = 'Update itinerary';
    }
    let showApprovalButtons = false;
    if (activeTab === 'Pricing'
      && this.itineraryActionType === ITINERARY_ACTIONS_TYPES.APPROVE_REJECT_ITINERARY) {
      showApprovalButtons = true;
    }
    const activeItinerary = (this.activeRequest?.itineraries || []).find((i) => i._id === this.itineraryID);
    if (itinerarySendSuccess) {
      return (
        <div className={classes.container}>
          <DocumentSuccessIcon className={classes.successIcon} />
          <Typography className={classes.successTitle}>
            Success
          </Typography>
          <Typography className={classes.successDescription}>
            You itinerary plan has been successfully sent to the customer
          </Typography>
          <Button
            onClick={this.dashboardHandler}
          >
            Go to dashboard
          </Button>
        </div>
      );
    }
    return (
      <div className={classes.container}>
        {activeTab ? this.renderItineraryDetails() : null}
        <Dialog
          open={deleteConfirmation}
          onClose={this.dismissDeleteConfirmation}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="requirement"
        >
          <Confirmation
            heading="Confirm deletion ?"
            description="Are you sure you want to delete ?"
            onReject={this.dismissDeleteConfirmation}
            onDismiss={this.dismissDeleteConfirmation}
            onConfirm={this.deleteConfirmation}
          />
        </Dialog>
        <Dialog
          open={sendConfirmationDialog}
          onClose={() => this.handleChange('sendConfirmationDialog', false)}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="confirmation"
        >
          <Confirmation
            heading="Send to customer without price"
            description="Are you sure, you want to send this itinerary to customer without land package pricing?"
            onReject={() => this.handleChange('sendConfirmationDialog', false)}
            onDismiss={() => this.handleChange('sendConfirmationDialog', false)}
            onConfirm={() => this.handleSendConfirmation('send_without_price')}
          />
        </Dialog>
        <Dialog
          open={sendWithSellingPriceDialog}
          onClose={() => this.handleChange('sendWithSellingPriceDialog', false)}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="confirmation"
        >
          <Confirmation
            heading="Are you sure you want to send this itinerary ?"
            description={`Land SP ₹${pricing.landPackage?.sellPrice}${pricing.flights?.sellPrice > 0 ? ` and Flight SP ₹${pricing.flights.sellPrice}` : ''}`}
            onReject={() => this.handleItinerarySendConfirmation('reject')}
            onDismiss={() => this.handleItinerarySendConfirmation('reject')}
            onConfirm={() => this.handleItinerarySendConfirmation('accept')}
          />
        </Dialog>
        <Dialog
          open={bookingConfirmationDialog}
          onClose={() => this.handleChange('bookingConfirmationDialog', false)}
          classes={{
            paper: classes.paper,
          }}
          aria-labelledby="confirmation"
        >
          <Confirmation
            heading="Are you sure you want to request booking ?"
            description={`You have entered Land CP: ${pricing.landPackage?.costPrice}
& Land SP: ${pricing.landPackage?.sellPrice - pricing.discount}.`}
            onReject={() => this.handleChange('bookingConfirmationDialog', false)}
            onDismiss={() => this.handleChange('bookingConfirmationDialog', false)}
            onConfirm={() => this.submitHandler(false)}
          />
        </Dialog>
        <div className={classes.itineraryContainer}>
          {isLoading
            ? (
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
              >
                <Loader />
              </div>
            ) : (
              <div className={classes.body}>
                <div className={classes.tabSection}>
                  {this.renderTabs()}
                </div>
                <div className={classes.sectionContainer}>
                  {this.renderActiveTab()}
                </div>
              </div>
            )}
        </div>
        <div className={classes.footerSection}>
          <Footer
            errorMsg={errorMsg[activeTab]}
            extraClass={classes.footer}
          >
            {showApprovalButtons ? (
              <>
                <Button
                  action="error"
                  variant="outlined"
                  loading={isLoading}
                  onClick={() => this.submitHandler(false, false)}
                  className={classes.button}
                >
                  Reject
                </Button>
                <Button
                  loading={isLoading}
                  className={classes.button}
                  onClick={() => this.submitHandler(false, true)}
                >
                  Approve
                </Button>
              </>
            )
              : (
                <>
                  {(activeTab === 'Pricing'
                    && ![ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY, ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING].includes(this.itineraryActionType)
                    && activeItinerary?.status !== 'ITINERARY_SENT'
                    && !ITINERARY_READ_MODES[this.itineraryActionType]) ? (
                      <Button
                        variant="outlined"
                        loading={isLoading}
                        onClick={() => this.submitHandler(true)}
                        className={classes.saveDraftBtn}
                      >
                        {saveButtonText}
                      </Button>
                    ) : null}
                  <Button
                    loading={isLoading}
                    onClick={this.nextHandler}
                  >
                    {buttonText}
                  </Button>
                </>
              )}
          </Footer>
        </div>
      </div>
    );
  }
}

/**
 * @param {import('@material-ui/core/styles/createMuiTheme').Theme} theme
 */
const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100vh - 56px)',
    overflowY: 'auto',
    backgroundColor: theme.colors.white,
  },
  successIcon: {
    height: 72,
    width: 72,
    color: theme.colors.primary,
  },
  successTitle: {
    fontSize: 24,
    color: theme.colors.black,
    fontWeight: 'bold',
    padding: '20px 0',
    textAlign: 'center',
  },
  successDescription: {
    fontSize: 14,
    color: theme.colors.textLight,
    textAlign: 'center',
    paddingBottom: 30,
  },
  loader: {
    position: 'absolute',
    width: '100%',
    top: 74,
    left: 0,
    color: theme.colors.primary,
  },
  initialLoader: {
    top: 56,
  },
  itineraryContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    boxSizing: 'border-box',
  },
  body: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '0 48px',
    boxSizing: 'border-box',
    marginTop: 80,
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    outline: 'none',
    border: 'none',
    justifyContent: 'end',
    height: '100%',
    alignItems: 'stretch',
    '&:hover': {
      backgroundColor: 'none',
    },
  },
  tabSection: {
    display: 'flex',
    flexDirection: 'column',
    width: 210,
    marginTop: 10,
  },
  tripRequirement: {
    width: 'auto',
  },
  sectionContainer: {
    maxHeight: 690,
    overflowY: 'auto',
    width: '100%',
    display: 'flex',
    minHeight: 480,
    borderRadius: 2,
    border: `1px solid ${theme.colors.primaryBackground}`,
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    color: theme.colors.textLight,
    fontWeight: 'bold',
    marginTop: -10,
    padding: '8px 8px 8px 14px',
    width: '100%',
    textAlign: 'left',
    marginBottom: '32px',
    height: 32,
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: theme.colors.background,
    },
  },
  activeTab: {
    color: theme.colors.primary,
    fontWeight: 'bolder',
    backgroundColor: `${theme.colors.yellowLight_1} !important`,
  },
  lineClass: {
    backgroundColor: theme.colors.primary,
    marginRight: 0,
  },
  lastDot: {
    marginRight: 0,
  },
  activeDot: {
    '&::before': {
      content: '""',
      position: 'absolute',
      left: -18,
      height: 32,
      top: 0,
      width: 32,
      borderRadius: '50% 0 0 50%',
      marginTop: -10,
      backgroundColor: `${theme.colors.yellowLight_1} !important`,
    },
  },
  dotClass: {
    border: `2px solid ${theme.colors.primary}`,
    backgroundColor: theme.colors.primary,
  },
  footerSection: {
    width: '100%',
    boxShadow: '0 -2px 8px 0 rgba(0,0,0,0.17)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  footer: {
    padding: '20px 40px',
  },
  saveDraftBtn: {
    marginRight: 20,
    width: 140,
  },
  userContainer: {
    boxSizing: 'border-box',
    width: '100%',
    paddingLeft: '8%',
    height: '74px',
    top: '0',
    paddingTop: '8px',
    left: '0',
    display: 'flex',
    position: 'absolute',
    alignItems: 'flex-start',
    flexDirection: 'column',
    background: 'linear-gradient(90deg, #3AAEA9 0%, #16242A 100%)',
  },
  userName: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.colors.white,
    marginBottom: 10,
  },
  userRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  userSection: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: 20,
  },
  userTravelInfo: {
    fontSize: 12,
    color: theme.colors.white,
  },
  userInfoIcon: {
    color: theme.colors.white,
    width: 16,
    height: 16,
    marginRight: 10,
  },
  button: {
    width: 140,
    margin: '0 10px',
  },
});

ItineraryMaker.propTypes = {
  history: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  suggestions: PropTypes.object.isRequired,
  getAutocompleteSuggestions: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  createItinerary: PropTypes.func.isRequired,
  getRequestById: PropTypes.func.isRequired,
  requestById: PropTypes.object.isRequired,
  isGettingRequestByID: PropTypes.bool.isRequired,
  getRequestByIDError: PropTypes.bool.isRequired,
  getRequestByIDErrorMsg: PropTypes.string.isRequired,
  saveItineraryDraft: PropTypes.func.isRequired,
  isSavingItineraryDraft: PropTypes.bool.isRequired,
  saveItineraryDraftResp: PropTypes.object.isRequired,
  saveItineraryDraftError: PropTypes.bool.isRequired,
  saveItineraryDraftErrorMsg: PropTypes.string.isRequired,
  isFetchingSuggestions: PropTypes.bool.isRequired,
  getStayRooms: PropTypes.func.isRequired,
  isGettingStayRooms: PropTypes.bool.isRequired,
  getStayRoomsResp: PropTypes.object.isRequired,
  getStayRoomsError: PropTypes.bool.isRequired,
  getStayRoomsErrorMsg: PropTypes.string.isRequired,
  getItineraryPrice: PropTypes.func.isRequired,
  isGettingItineraryPrice: PropTypes.bool.isRequired,
  itineraryPriceResp: PropTypes.object.isRequired,
  itineraryPriceError: PropTypes.bool.isRequired,
  itineraryPriceErrorMsg: PropTypes.string.isRequired,
  updateStatus: PropTypes.func.isRequired,
  isUpdatingStatus: PropTypes.bool.isRequired,
  updateStatusResp: PropTypes.object.isRequired,
  updateStatusError: PropTypes.bool.isRequired,
  updateStatusErrorMsg: PropTypes.string.isRequired,
  conversion: PropTypes.object.isRequired,
  getConversion: PropTypes.func.isRequired,
  expert: PropTypes.object.isRequired,
  bookingAction: PropTypes.func.isRequired,
  isBooking: PropTypes.bool,
  bookingActionResp: PropTypes.object,
  bookingActionError: PropTypes.bool,
  bookingActionErrorMsg: PropTypes.string,
};

export default hot(withStyles(styles)(ItineraryMaker));
